import { notNull } from "@warrenio/utils/notNull";
import type { Promisable } from "type-fest";
import type { StandardRequest } from "./StandardRequest.ts";

export type MockResponse = Promisable<Response | undefined>;

export interface MatcherParams {
    /** Number of requests to this endpoint */
    count: number;
    path: string;
    /** Check if the path matches a pattern */
    pathMatches: ReturnType<typeof makePathMatcher>;
}

export type MockRequestMatcher = (params: MatcherParams) => MockResponse;

let matchers: MockRequestMatcher[] = [];

export function setMatchers(newMatchers: MockRequestMatcher[]) {
    resetRequestCounts();
    matchers = newMatchers;
}

function makePathMatcher(url: URL) {
    return (path: string) => {
        // TODO: implement something more intelligent
        return url.pathname.includes(path);
    };
}

export function matchRequest(r: StandardRequest) {
    const url = new URL(r.request.url);
    const params = {
        path: url.pathname,
        pathMatches: makePathMatcher(url),
        count: notNull(r.count),
    };

    for (const matcher of matchers) {
        const response = matcher(params);
        if (response !== undefined) {
            return response;
        }
    }

    return undefined;
}

//#region Request counting

const requestCounts = new Map<string, number>();

function resetRequestCounts() {
    requestCounts.clear();
}

function getRequestKey(r: StandardRequest) {
    const url = new URL(r.request.url);
    return `${r.request.method} ${url.pathname}`;
}

/** Update request counters */
export function countRequest(r: StandardRequest) {
    const key = getRequestKey(r);
    const count = requestCounts.get(key) ?? 0;
    requestCounts.set(key, count + 1);

    r.countKey = key;
    r.count = count;

    return count;
}

//#endregion
